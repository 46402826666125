import React, { useEffect, useMemo } from "react";

import { Box, IconButton, Link, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTimeLeftCountDown } from "@remar/shared/dist/hooks/useTimeLeftCountDown";
import clsx from "clsx";
import { addMinutes, isAfter } from "date-fns";

import { useAppDispatch, useAppSelector } from "store";
import {
	applyValidatedCoupon,
	hideBannerOnCancelSub,
	selectInactiveSubscription,
	selectIsTrial,
	setStateValue,
	setUpgradeModal
} from "store/features/Auth/authSlice";
import { IBannerData } from "store/features/notifications/notifications.model";
import { setBannerData } from "store/features/notifications/notifications.slice";

import { BannerContainer, useStyles } from "./styles";

import { GLOBAL_CONSTANTS } from "../../../constants";

interface ISaleBanner {
	startDate: string;
	endDate: string;
	copy: string;
	background: string;
	isTrial: boolean;
}

type SaleBannerProps = Pick<ISaleBanner, "copy">;
const SaleBanner = ({ copy }: SaleBannerProps) => <Box>{decodeURIComponent(atob(copy))}</Box>;

const Banner = ({ bannerData }: { bannerData: IBannerData }) => {
	const { bannerMessage, duration, createdAt, expiresAt, isDismissible = false, takeOver = false, coupon } = bannerData;
	const classes = useStyles();
	const dispatch = useAppDispatch();

	const trial = useAppSelector(selectIsTrial);
	const { isRenewEnabled } = useAppSelector(selectInactiveSubscription);

	const { onStart, timeLeft, isStarted } = useTimeLeftCountDown();

	useEffect(() => {
		if (duration && createdAt) {
			const { days = 0, hours = 0, minutes = 0 } = duration;
			const daysToMinutes = days * 24 * 60;
			const hoursToMinutes = hours * 60;
			const totalMinutes = daysToMinutes + hoursToMinutes + minutes;
			onStart(addMinutes(new Date(createdAt), totalMinutes));
		}
	}, [duration, createdAt]);

	const saleBannersToShow = useMemo(() => {
		const { SALE_BANNERS } = GLOBAL_CONSTANTS;
		return SALE_BANNERS
			? (JSON.parse(SALE_BANNERS) as Array<ISaleBanner>).filter(({ endDate, startDate }) => {
					const now = new Date();
					return new Date(startDate) < now && new Date(endDate) > now;
			  })
			: [];
	}, []);

	const canApplyCoupon = useMemo(
		() => isStarted || (expiresAt && isAfter(new Date(expiresAt), new Date())),
		[isStarted, expiresAt]
	);
	const bannerMessageText = useMemo(() => {
		if (trial && !isRenewEnabled) {
			if (saleBannersToShow.length) {
				return <SaleBanner {...saleBannersToShow[0]} />;
			}
			if (takeOver && canApplyCoupon) {
				return bannerMessage;
			}
			return (
				<>
					<Link className={classes.manageSubscriptionBtn}>Click 𝐇𝐞𝐫𝐞 to upgrade your subscription</Link>
				</>
			);
		}
		return bannerMessage;
	}, [trial, isRenewEnabled, saleBannersToShow, bannerMessage, canApplyCoupon]);

	const showCloseIcon = useMemo(() => isDismissible, [isDismissible]);
	return (
		<>
			<Box
				onClick={() => {
					if (trial) {
						if (!!coupon && canApplyCoupon) {
							dispatch(applyValidatedCoupon(coupon));
						} else {
							dispatch(setStateValue({ key: "validatedCoupon", value: null }));
						}
						dispatch(setUpgradeModal(true));
					}
				}}
				bgcolor={trial && saleBannersToShow.length && saleBannersToShow[0].background + "! important"}
				className={clsx(classes.defaultNotice, classes.trialNotice)}
			>
				<Box display={"flex"} alignItems={"center"}>
					<BannerContainer>
						<Typography className={clsx(classes.bannerMessage, classes.trialText)}>{bannerMessageText}</Typography>
					</BannerContainer>
					<Typography style={{ marginLeft: "10px" }} className={classes.trialText} align={"center"}>
						{timeLeft}
					</Typography>
				</Box>
				{showCloseIcon && (
					<Box display={"flex"} alignItems={"end"}>
						<IconButton
							style={{ alignItems: "flex-end" }}
							size="small"
							className={classes.closeNotice}
							onClick={event => {
								// eslint-disable-line
								event.stopPropagation(); // Prevents the parent onClick from firing
								dispatch(setBannerData({ showBanner: false }));
								dispatch(hideBannerOnCancelSub());
							}}
						>
							<Close />
						</IconButton>
					</Box>
				)}
			</Box>
		</>
	);
};

export default Banner;
